<!-- @format -->

<template>
  <b-card no-body>
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">成员信息 <small>(点击查看)</small></h5>
      <b-badge variant="light-primary">
        更新时间
        <small class="text-muted w-100">{{ new Date().toLocaleString() }}</small>
      </b-badge>
    </b-card-header>
    <b-card-body>
      <b-row class="flex justify-content-between">
        <div style="width: 48%" class="rounded-lg pl-2">
          <ul class="list-unstyled my-1">
            <li v-v-b-toggle="'collapse_active'">
              <span class="align-middle">
                活跃用户: {{ 0 }}
                <feather-icon icon="ChevronDownIcon" class="ml-2" />
              </span>
            </li>
          </ul>
          <b-collapse id="collapse_active">
            <div v-if="data && data.active && data.active.list.length > 0">
              <div class="list-unstyled my-1">
                <div v-for="item in data.active.list" :key="item.id">
                  <span class="align-middle mr-75">{{ item.real_name }}</span>
                  <span class="align-middle mr-75">{{ item.mobile }}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="align-middle">暂无活跃用户</span>
            </div>
          </b-collapse>
        </div>
        <div style="width: 48%" class="rounded-lg pl-2">
          <ul class="list-unstyled my-1">
            <li>
              <span class="align-middle" v-v-b-toggle="'collapse_notActive'">
                不活跃用户数: {{ data && data.notActive && data.notActive.count }}
                <feather-icon icon="ChevronDownIcon" class="ml-2" />
              </span>
            </li>
          </ul>
          <b-collapse id="collapse_notActive" class="mt-2">
            <div class="my-25" v-if="data && data.notActive && data.notActive.list.length > 0">
              <div class="list-unstyled my-1" style="list-style: none">
                <div v-for="item in data.notActive.list" :key="item.id">
                  <span class="align-middle mr-75">{{ item.real_name }}</span>
                  <span class="align-middle mr-75">{{ item.mobile }}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <span class="align-middle">暂无不活跃用户</span>
            </div>
          </b-collapse>
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
// eslint-disable-next-line import/no-duplicates
import { BCard, BCardHeader, BCardBody, BBadge, BButton, BCollapse } from 'bootstrap-vue'
// eslint-disable-next-line import/no-duplicates
import { VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    VBToggle,
  },
  components: {
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    data: {
      type: [Object],
      default: () => ({
        active: {
          count: 0,
          list: [],
        },
        notActive: {
          count: 0,
          list: [],
        },
      }),
    },
  },
}
</script>

<style></style>
