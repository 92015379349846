<!-- @format -->

<template>
  <b-card>
    <b-row style="display:flex;">
      <b-col xl="6" lg="12" md="12" class="d-flex justify-content-between flex-column">
        <div class="d-flex justify-content-start" style="height: 400px">
          <b-avatar :src="userData.avatar || filterSrc" :text="avatarText(userData.real_name)" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0" v-if="userData.firstname || userData.lastname">
                {{ userData.firstname }} {{ userData.lastname }}
              </h4>
              <h4 class="mb-0" v-else>
                {{ userData.real_name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button :to="{ name: 'apps-users-edit', params: userData }" variant="outline-primary" class="ml-1 modify-informatio"> Modify information </b-button>
              <!-- <b-button :to="{ path: '/pages/account-setting' }" variant="outline-warning" class="ml-1 change-password"> Change password </b-button> -->
            </div>
            <img class="user-code" :src="userData.code_url" alt="">
            <div style="position: absolute;left: 20px;bottom: 0;font-weight: bold">
              Agent ID: {{ userData.id }}

            </div>
          </div>
        </div>

        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="DollarSignIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">23.3k</h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="TrendingUpIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">$99.87k</h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <b-col xl="6" lg="12" md="12">
        <table class="mt-2 mt-xl-0 w-100 info-list">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Agent ID</span>
            </th>
            <td class="pb-50">
              {{ userData.id }}
            </td>
          </tr>
          <!-- <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Agent name</span>
            </th>
            <td class="pb-50 text-capitalize" v-if="userData.firstname || userData.lastname">{{ userData.firstname }} -- {{ userData.lastname }}</td>
            <td v-else>
              {{ userData.real_name }} -- {{ userData.gender }}
            </td>
          </tr> -->
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">First Name</span>
            </th>
            <td class="pb-50 text-capitalize">{{ userData.firstname }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Last Name</span>
            </th>
            <td class="pb-50 text-capitalize">{{ userData.lastname }}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Country</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.country }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="FlagIcon" class="mr-75" />
              <span class="font-weight-bold">Phone Number</span>
            </th>
            <td class="pb-50">
              {{ userData.mobile }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">Address 1</span>
            </th>
            <td class="pb-50">
              {{ userData.address || 'No information yet ~~' }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">Address 2</span>
            </th>
            <td class="pb-50">
              {{ userData.address2 || 'No information yet ~~' }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">State</span>
            </th>
            <td class="pb-50">
              {{ userData.state || 'No information yet ~~' }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">City</span>
            </th>
            <td class="pb-50">
              {{ userData.city || 'No information yet ~~' }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="MapPinIcon" class="mr-75" />
              <span class="font-weight-bold">Sponsor Agent</span>
            </th>
            <td class="pb-50">
              {{ userData.pid || 'No information yet ~~' }}
            </td>
          </tr>
        </table>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  filters: {
    filterSrc(val) {
      const deleString = 'https://tmp.bxapi.sdbaizhi.com/'
      const reg = new RegExp(deleString, 'g')
      return process.env.VUE_APP_BASE_URL.replace('/backend', '/') + val.replace(reg, '')
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.user-code {
  width:100%;
  max-width:220px;
  position: absolute;
  left: 20px;
  bottom: 30px;
}
@media screen and (max-width: 992px) {
  .modify-informatio,
  .change-password {
    margin-left: 0 !important;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .user-code {
    top: 180px;
    bottom: auto;
  }
}
.info-list {
  th {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    span {
      color: #000;
      font-size: 1.5rem;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  td {
    color: #000;
    font-size: 1.5rem;
  }
}
</style>
