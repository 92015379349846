<template>
  <div>
    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <user-view-user-info-card :user-data="userData" />

        </b-col>
      </b-row>
    </template>
    <AccountSetting :dataa="userData" :addr="'vip_home'" />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { BRow, BCol, } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import AccountSetting from '@/views/pages/account-setting/AccountSetting.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
// import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
export default {
  components: {
    AccountSetting,
    BRow,
    BCol,
    // // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    // UserViewUserPermissionsCard,
  },
  setup() {
    const userData = ref(null)

    const childUserData = ref(null)
    onMounted(() => {
      useJwt.info({}).then(res => {
        // console.log(res)
        userData.value = res.data
      })
      // useJwt.offlineTree().then(response => {
      //   childUserData.value = response.data
      //   // console.log(childUserData.value)
      // })
    })

    return {
      userData,
      childUserData,
    }
  },
}
</script>

<style></style>
