<!-- @format -->

<template>
  <b-card :title="text" class="diy_pl">
    <template v-if="childrenData && childrenData.length && childrenData.length > 0">
      <app-timeline>
        <app-timeline-item v-for="item in childrenData" :key="item.id" class="pl-1">
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0 cursor-pointer" @click.prevent="getChildren(item.id, item)">
            <h6>{{ item.real_name }} &nbsp;&nbsp;&nbsp;&nbsp; 电话:{{ item.mobile }} 邮箱:{{ item.email }}</h6>
            <feather-icon icon="ChevronRightIcon" />
          </div>
          <div v-if="item.children && item.children.length > 0 && item.active" class="mt-1">
            <UserViewUserTimelineCard :data="item.children" :type="type" />
          </div>
        </app-timeline-item>
      </app-timeline>
    </template>
    <template v-else>
      <app-timeline>
        <app-timeline-item> 暂无数据 </app-timeline-item>
      </app-timeline>
    </template>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axiosIns from '@/libs/axios'

export default {
  name: 'UserViewUserTimelineCard',
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    data: {
      type: [Object, Array],
      default: () => ({}),
    },
    text: {
      type: String,
      default: '',
    },
    type: String,
  },
  data() {
    return {
      childrenData: this.data,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.childrenData = this.data.map(item => {
        item.active = false
        this.$forceUpdate()
        return item
      })
    })
  },
  methods: {
    getChildren(id, item) {
      item.active = !item.active
      if (!item.active) {
        item.children = []
        this.$forceUpdate()
      } else {
        axiosIns({
          url: `/user/childrenUser?user_id=${id}`,
          method: 'get',
        }).then(res => {
          const activeType = this.type == 'active' ? 'active' : 'notActive'
          if (res.data[activeType].count > 0) {
            if (!item.children) {
              (item.children, (item.children = [])).push(...res.data[activeType].list)
            } else {
              item.children.push(...res.data[activeType].list)
            }
            // item.children.
            this.$forceUpdate()
          } else {
            this.$bvToast.toast(`${item.real_name}没有下级用户`, {
              title: '提示',
              variant: 'warning',
              solid: true,
              autoHideDelay: 2000,
            })
          }
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.diy_pl {
  margin-bottom: 10px;
  .card-body {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
